const baseUrl = "https://maktabalabs.netlify.app";

module.exports = {
  title: "النسخة التجريبية من تطبيق مكتبة",
  logo: `${baseUrl}/favicon/logo-48.png`, // to be changed
  url: baseUrl, // to be changed
  favicon: `${baseUrl}/favicon/favicon.ico`, // to be changed
  cover: `${baseUrl}/cover.png`, // to be changed
  legalName: "مكتبة",
  description:
    "كن أول من يستخدم النسخة التجريبية من تطبيق مكتبة ! سوف تتلقى دعوتك فور جاهزية التطبيق قريبا.",
  socialLinks: {
    twitter: "",
    github: "",
    linkedin: "",
    instagram: "",
    youtube: "",
    google: "",
  },
  themeColor: "#000696",
  backgroundColor: "#6496f6",
  social: {
    facebook: "",
    twitter: "",
  },
  address: {
    city: "", // to be changed
    region: "", // to be changed
    country: "",
    zipCode: "-", // to be changed
  },
  contact: {
    email: "", // to be changed
    phone: "+xxx-xx-xx-xx-xx", // to be changed
  },
  foundingDate: "2020",
};
