import React from 'react'
import styled from 'styled-components'
import { Seo } from '../Seo'
import './layout.scss'

const Layout = ({ children }) => (
	<LayoutWrapper>
		<Seo />
		{children}
	</LayoutWrapper>
)

const LayoutWrapper = styled.div`
		max-width: 1200px;
		margin: 0 auto;
		height: 100vh;
		width: 70%;
		@media (max-width: 1300px) {
			width: 80%;
		}
		@media (max-width: 1024px) {
			height: 100%;
			width: 90%
		}
`

export { Layout }
